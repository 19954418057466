@import "ota-theme";

*{
  margin: 0;
  box-sizing: border-box;
  font-family: FordAntennaRegular;
}

.splash-screen {
  background-color: #1376AA;
  height: 100%;
  width: 100%;
  position: fixed;
}

.splash-ford-oval {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 150px;
  max-width: 150px;
  transform: translate(-50%, -50%);
  z-index: 10;
}

.splash-screen-loader {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: block;
  position: absolute;
  margin: auto;
  width: 350px;
  height: 150px;

  > div {
    background-color: #F3F3F3;
    border-radius: 100%;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    position: absolute;
    opacity: 0;
    width: 350px;
    height: 150px;
    -webkit-animation: splash-screen-anim 1s 0s linear infinite;
    animation: splash-screen-anim 1s 0s linear infinite;
  }

  > div:nth-child(2) {
    -webkit-animation-delay: 0.33333s;
    animation-delay: 0.33333s;
  }

  > div:nth-child(3) {
    -webkit-animation-delay: 0.66666s;
    animation-delay: 0.66666s;
  }
}

@-webkit-keyframes splash-screen-anim {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  5% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes splash-screen-anim {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  5% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

